import { useState, useContext } from 'react';
import { Stack, TextField, FormLabel, Button, Chip, Typography, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getPageRoute } from 'src/services';
import { AuthContext } from "src/utils/AuthContext.js";
import { useGetUserCalendars } from "src/services";
import { LoadingButton } from './LoadingButton.js';

function TagSelector({ tags, typeGroup, handleTagChange }) {
  return (
    <Stack direction="column" sx={{ flexWrap: 'wrap' }}>
      <FormLabel component="legend">{typeGroup ? 'Set tags for your group:' : 'Set tags for your calendar:'}</FormLabel>
      <Stack direction="row" sx={{ flexWrap: 'wrap', mt: 2 }}>
        {process.env.REACT_APP_DEFAULT_TAGS?.split(',').map(tag => tag.trim()).map((tag) => (
          <Chip
            data-testid={`import-calendar-tag-${tag}`}
            label={`${tag}`}
            id={`${tag}`}
            key={`${tag}`}
            name={`${tag}`}
            color={tags && tags.some((t) => t === tag) ? "primary" : "default"}
            variant={tags && tags.some((t) => t === tag) ? "default" : "outlined"}
            onClick={() => {
              console.log(`Tag ${tag} changed to ${!(tags && tags.some((t) => t === tag))}`);
              handleTagChange(tag, !(tags && tags.some((t) => t === tag)));
            }}
            sx={{ mb: 1, mr: 1 }}
          />
        ))}
      </Stack>
    </Stack>
  );
}

function GroupCalendars({ calendars }) {
  const navigate = useNavigate();
  const [groupCalendars, setGroupCalendars] = useState(calendars);
  const { user, refetchAuth } = useContext(AuthContext);
  const { refetch: refetchUserCalendars, isLoading } = useGetUserCalendars({ user })

  // Remove calendar from group
  const handleRemoveCalendarFromGroup = async (calendarId) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/group/removecalendar`, { calendarId: calendarId, }, { withCredentials: true });
      // Remove the deleted calendar from the state
      setGroupCalendars((prevCalendars) =>
        prevCalendars.filter((calendar) => calendar.id !== calendarId)
      );
      refetchUserCalendars();
      refetchAuth();
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <Stack direction="column" sx={{ flexWrap: 'wrap' }}>
      <FormLabel component="legend">Edit calendars in your group:</FormLabel>
      <Stack direction="row" sx={{ flexWrap: 'wrap', mt: 2 }}>
        {groupCalendars.map((calendar) => (
          <Chip
            label={`${calendar.name}`}
            id={`${calendar.id}`}
            key={`${calendar.id}`}
            name={`${calendar.name}`}
            color="default"
            variant="outlined"
            onClick={() => {
              navigate(`/${calendar.handle}/${calendar.id}`);;
            }}
            onDelete={
              groupCalendars.length >= 2
                ? () => handleRemoveCalendarFromGroup(calendar.id)
                : undefined
            }
            sx={{ mb: 1, mr: 1 }}
          />
        ))}
      </Stack>
    </Stack>
  );
}

function ImportOrEditForm({ name, setName, description, setDescription, link, setLink, typeGroup, urlMessage, validCalendarMessage, tags, handleTagChange, handleSave, calendars, isLoading }) {
  return (
    <form data-testid={typeGroup ? "form-edit-group" : "import-calendar-save"} onSubmit={(event) => {
      // Keep the page from refreshing
      event.preventDefault();
      handleSave()
    }}>
      <Stack spacing={3}>
        <TextField
          required
          data-testid={typeGroup ? "group-name" : "import-calendar-name"}
          value={name}
          label={typeGroup ? 'Group Name' : 'Calendar Name'}
          variant="standard"
          onChange={(event) => setName(event.target.value)}
        />
        <TextField
          required
          data-testid={typeGroup ? "group-description" : "import-calendar-description"}
          multiline
          value={description}
          label={typeGroup ? 'Group Description' : 'Calendar Description'}
          variant="standard"
          onChange={(event) => setDescription(event.target.value)}
        />
        {(typeGroup || link === 'scraped') ? (<> </>) : (
          <TextField
            required
            data-testid="import-calendar-public-url"
            multiline
            error={!!urlMessage || !!validCalendarMessage}
            value={link}
            label="Public .ics URL for your calendar"
            helperText={urlMessage ? urlMessage : validCalendarMessage ? validCalendarMessage : <Typography variant="caption">Need help? Watch video {" "}
              <Link href={getPageRoute("help", "HELP_IMPORT")} target="_blank" rel="noreferrer">here</Link>.
            </Typography>}
            variant="standard"
            onChange={(event) => setLink(event.target.value)}
          />
        )}
        {typeGroup && (
          <GroupCalendars calendars={calendars} />
        )}
        <TagSelector tags={tags} typeGroup={typeGroup} handleTagChange={handleTagChange} />
      </Stack>
      <LoadingButton variant="outlined" type="submit" size="large" loading={isLoading} sx={{ float: 'right', mb: 4 }}>Save</LoadingButton>
    </form>
  );
}

export default ImportOrEditForm;